import { useMemo } from 'react'
import { useGetCachedRequest } from 'api'
import { type BrandDTO } from 'ecosystem'

export const useGetBrands = (endpointPath: string, options?: { onlyVisibleBrands?: boolean }) => {
  const path = useMemo(() => {
    const onlyVisibleBrands =
      options?.onlyVisibleBrands !== undefined ? options.onlyVisibleBrands : true

    const separator = endpointPath.includes('?') ? '&' : '?'
    return `${endpointPath}${separator}onlyVisibleBrands=${onlyVisibleBrands}`
  }, [endpointPath, options?.onlyVisibleBrands])

  return useGetCachedRequest<BrandDTO[]>(path)
}
