import storefrontApi from 'api/storefront-api'
import type { BrandDTO, BrandGroup, Language, OldStoreProductFilterDTO, StoreDTO } from 'ecosystem'
import { DynamicWidgetType } from 'ecosystem'
import type { StaticPath } from 'shared-utils'
import { groupByFirstLetter } from 'shared-utils'
import { brandFactory, dynamicPageWidgets, useLocaleCredentials as setLocaleCredentials } from 'api'
import type { NextApiRequest, NextApiResponse } from 'next'
import { notFound } from 'next/navigation'

export const getStaticPropsHandler = async <T>(translations?: T) => {
  const store = await storefrontApi.store.getStore({ query: ['brands'] })
  if (!store.brands) {
    return notFound()
  }

  return {
    props: {
      ...translations,
      brands: groupByFirstLetter(store.brands) as BrandGroup[]
    }
  }
}

export const getStaticPropsDetailHandler = async <T>(
  slug: string,
  priceValues: {
    minPrice: number
    maxPrice: number
  },
  translations?: T
) => {
  const store = await storefrontApi.store.getStore({
    query: ['brands', 'deliveries']
  })
  const brand = brandFactory(slug, store.brands)

  if (!brand) {
    return notFound()
  }

  const filterOptionsResponse = await storefrontApi.store.getFilterOptions({
    brandIds: [brand.id],
    filters: [],
    ...priceValues
  })

  const widgets = await dynamicPageWidgets({ id: brand.id, type: 'brand' }, DynamicWidgetType.BRAND)

  const props = {
    ...translations,
    deliveryOptions: store.deliveries || null,
    brand,
    widgets,
    filterConfig: {
      options: filterOptionsResponse?.options || [],
      brands: [] //no need for a "brands" filter in the Brand page
    } as OldStoreProductFilterDTO
  }

  return {
    props
  }
}

export const getStaticPathsHandler = async () => {
  const store = await storefrontApi.store.getStore({ query: ['brands'] })
  const mapper = (brand: BrandDTO): StaticPath => ({ params: { slug: brand.slug.toString() } })
  const paths = store.brands
    ?.map(mapper)
    .flat()
    .filter((p) => p.params.slug !== '')
  return { paths: paths || [], fallback: 'blocking' }
}

export const getBrandsHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<BrandDTO[] | { error: string }>
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (req.method !== 'GET') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  let brands: BrandDTO[] | undefined
  let error: Error | undefined

  const onlyVisibleBrands = req.query.onlyVisibleBrands !== 'false'

  try {
    const store: StoreDTO = await storefrontApi.store.getStore({
      query: ['brands'],
      onlyVisibleBrands
    })
    brands = store.brands ?? undefined
  } catch (err: unknown) {
    error = err as Error
  }

  if (error || !brands) {
    res.status(400).json({ error: error?.message || 'Unknown error' })
    return
  }

  brands.sort((a, b) => a.name.localeCompare(b.name))

  res.status(200).json(brands)
}
